<template>
  <div class="container-api-keys">
    <h4>Api Keys</h4>
    <div class="form-wrapper">
      <b-form-group class="form-group">
        <b-form-input
          class="input"
          autocomplete="off"
          placeholder="Nome da chave"
          v-model="name"
        />
      </b-form-group>
      <b-button
        class="button"
        size="sm"
        variant="primary"
        :disabled="!name"
        @click="createApiKey"
      >
        Criar
      </b-button>
    </div>

    <b-table-simple>
      <thead>
        <tr>
          <td class="td-head">Nome</td>
          <td class="td-head">Key</td>
          <td class="td-head">Criado em</td>
          <td class="td-head"></td>
        </tr>
      </thead>
      <tbody v-for="(apiKey, index) in apiKeys" :key="index">
        <tr>
          <td>{{ apiKey.name }}</td>
          <td>{{ apiKey.key }}</td>
          <td>{{ parseTime(apiKey.created_at) }}</td>
          <td>
            <Icon tooltip="Remover KEY">
              <Delete class="pointer" @click="destroyApiKey(apiKey)"/>
            </Icon>
          </td>
        </tr>
      </tbody>
    </b-table-simple>
  </div>
</template>
<script>
import Delete from '@/assets/icons/delete.svg'
import Icon from '@/components/General/Icon'

export default {
  components: { Delete, Icon },
  async mounted() {
    await this.getApiKeys()
  },
  data() {
    return {
      name: '',
      apiKeys: []
    }
  },
  methods: {
    async createApiKey() {
      try {
        await this.api.createApiKey({ name: this.name })
        await this.getApiKeys()
        this.$toast.success('Api key criada com sucesso')
        this.name = ''
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    async getApiKeys() {
      const { data } = await this.api.getApiKeys()
      this.apiKeys = data
    },
    async destroyApiKey(apiKey) {
      try {
        await this.api.destroyApiKey(apiKey.id)
        await this.getApiKeys()
        this.$toast.success('Api key removida com sucesso')
      } catch(err) {
        this.$toast.error(err.message)
      }
    },
    parseTime(time) {
      return this.moment(time).format('DD/MM/YYYY HH:mm')
    }
  }
}
</script>
<style lang="scss" scoped>
.container-api-keys {
  padding: 2rem;
}
h4 {
  font-weight: 700;
}
.form-wrapper {
  width: 100%;
  display: inline-flex;
  gap: 10px;
}
.form-group {
  width: 100%;
}
.input {
  width: 100%;
}
.button {
  height: 38px;
}

.td-head{
  border: none;
  background-color: var(--neutral-100);
  color: var(--dark-blue);
  font-weight: 700;
  font-size: 14px;
}
td {
  border: none !important;
  /* border-bottom: 1px solid var(--neutral-200); */
  vertical-align: middle;
}
.pointer {
  cursor: pointer;
}
</style>
